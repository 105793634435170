import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";

import { readState } from "@/__main__/app-state.mjs";
import { AgentImage, Outcome } from "@/game-val/CommonComponent.jsx";
import {
  calcWinrate,
  getNameTag,
  getOutcomeShort,
  getOutcomeShortColor,
  getValorantRankImage,
} from "@/game-val/utils.mjs";
import InfoTag from "@/inline-assets/info-tag.svg";
import { classNames } from "@/util/class-names.mjs";
import { calcRate, displayRate } from "@/util/helpers.mjs";
import { formatOrdinalNumber, getLocale } from "@/util/i18n-helper.mjs";
import orderBy from "@/util/order-array-by.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const CardContainer = styled("div")`
  position: relative;
  background: var(--shade7);
  border-radius: var(--br);
  --border-color: var(--turq);
  .col-position {
    flex: 0.5 1 0%;
    text-align: left;
  }
  .col-agent {
    display: flex;
    align-items: center;
    text-align: left;
    flex: 1.4 1 0%;
  }
  .gold-bar {
    width: var(--sp-1);
    height: var(--sp-13);
    background: var(--yellow);
    margin-right: var(--sp-3);
  }
  .col-kda,
  .col-winRate,
  .col-hs,
  .col-score,
  .col-adr,
  .col-kpm {
    flex: 0.7 1 0%;
  }
  .agent-img-container {
    box-sizing: border-box;
    margin-right: var(--sp-3);
    width: var(--sp-8);
    height: var(--sp-8);
    border-radius: var(--br);
    background: var(--border-color);
    img {
      border: var(--sp-0_5) solid var(--border-color);
    }
  }
  .col-history {
    flex: 0.8 1 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .rank-img-container {
    margin-right: var(--sp-3_5);
    width: var(--sp-5);
    height: var(--sp-6_5);
  }
`;

const PlayerRow = styled("a")`
  display: flex;
  align-items: center;
  height: var(--sp-13);
  text-align: center;
  padding: ${(props) =>
    props.$isUser ? "0 var(--sp-4) 0 0" : "0 var(--sp-4)"};
  color: var(--shade1);
  --border-color: ${(props) => props.$color};
  --user-background: linear-gradient(
    to left,
    rgba(239, 191, 108, 0) 0%,
    #efbf6c26 100%
  );
  background: ${(props) => (props.$isUser ? "var(--user-background)" : null)};
  &:nth-child(even) {
    background: ${(props) =>
      props.$isUser ? "var(--user-background)" : "var(--shade8)"};
  }
  .player-name {
    color: ${(props) => (props.$isUser ? "#FCC668" : "var(--shade0)")};
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: var(--sp-16);
  }
  .rank-name-container {
    display: flex;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .panel-title {
    &.team {
      color: var(--turq);
    }
    &.enemy {
      color: var(--red);
    }
    &.neutral {
      font-weight: 500;
      color: var(--shade1);
    }
  }
  .col-agent div {
    display: flex;
  }

  &.focusable:hover {
    background: ${(props) =>
      props.$isUser ? "var(--user-background)" : "var(--shade6-50)"};
  }

  &.unclickable {
    pointer-events: none;
    cursor: default;
  }
  .scoreboard-info {
    align-self: center;
    margin-left: var(--sp-2);
    svg {
      width: var(--sp-4_5);
      height: var(--sp-4_5);
    }
  }
`;

const Row = ({
  agentImg,
  title,
  competitiveTier,
  agentName,
  agentKey,
  playerName,
  kdaText,
  kda,
  killsPerMin,
  hsPercent,
  winRate,
  adr,
  avgScore,
  borderColor,
  isUser,
  isTitleRow = false,
  isEscalation,
  isDeathmatch,
  isTeamDeathmatch,
  position,
  href,
  isEnemy,
  history,
  incognito,
}) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const {
    val: { content: { ranks = [] } = {} },
  } = useSnapshot(readState);

  const playerRank = ranks.find((r) => r.position === competitiveTier);
  const rank = competitiveTier ? playerRank?.rank : null;
  const tier = competitiveTier ? playerRank?.tier : null;
  const rankImg = getValorantRankImage({ tier, rank });
  return (
    <PlayerRow
      $color={borderColor}
      $isUser={isUser}
      className={
        classNames(
          !isTitleRow ? "focusable" : "",
          incognito && !isUser ? "unclickable" : "",
        ).className
      }
      href={!incognito || isUser ? href : null}
    >
      <div className={isUser ? "gold-bar" : ""} />
      {isDeathmatch && (
        <div className="col-position">
          <p className="type-body2">
            {isTitleRow
              ? t("val:rank", "Rank")
              : formatOrdinalNumber(language, position)}
          </p>
        </div>
      )}
      <div className="col-agent">
        <div>
          <span
            className={
              classNames(
                "type-subtitle2",
                "panel-title",
                isEnemy ? "enemy" : "team",
                isDeathmatch ? "neutral" : "",
              ).className
            }
          >
            {title ? title : null}
          </span>
          {isTitleRow && !isEnemy ? (
            <span
              className="scoreboard-info"
              data-tip={t(
                "val:live.scoreboardInfo",
                "Scoreboard stats are based on the current game mode of the latest act.",
              )}
            >
              <InfoTag />
            </span>
          ) : null}
          <span className="rank-name-container">
            {!isTitleRow ? (
              <div className="agent-img-container">
                <AgentImage src={agentImg} />
              </div>
            ) : null}
            {!isTitleRow ? (
              <div className="rank-img-container">
                <AgentImage src={rankImg} />
              </div>
            ) : null}
            {agentKey ? (
              <p className="type-subtitle2 player-name">
                {incognito && !isUser
                  ? t(`val:agents.${agentKey}`, agentName)
                  : playerName}
              </p>
            ) : null}
          </span>
        </div>
      </div>
      {!isDeathmatch && !isEscalation && !isTeamDeathmatch ? (
        <>
          <div className="col-winRate">
            <p className={isTitleRow ? "type-caption" : "type-body2"}>
              {winRate}
            </p>
          </div>
          <div className="col-kda">
            <p className={isTitleRow ? "type-caption" : "type-body2"}>{kda}</p>
          </div>
          <div className="col-hs">
            <p className={isTitleRow ? "type-caption" : "type-body2"}>
              {hsPercent}
            </p>
          </div>
          <div className="col-adr">
            <p className={isTitleRow ? "type-caption" : "type-body2"}>{adr}</p>
          </div>
        </>
      ) : (
        <div className="col-kda">
          <p className={isTitleRow ? "type-caption" : "type-body2"}>
            {kdaText}
          </p>
        </div>
      )}
      <div className="col-score">
        <p className={isTitleRow ? "type-caption" : "type-body2"}>{avgScore}</p>
      </div>
      {!isDeathmatch && !isEscalation && !isTeamDeathmatch && (
        <div className="col-history">
          {isTitleRow ? (
            <p className={"type-caption"}>{history}</p>
          ) : (
            history?.map(({ won }, index) => {
              const outcome = won ? "win" : "loss";
              return (
                <Outcome className={outcome} key={index}>
                  <span
                    className="type-mini"
                    style={{
                      color: getOutcomeShortColor(outcome),
                    }}
                  >
                    {getOutcomeShort(t, outcome)}
                  </span>
                </Outcome>
              );
            })
          )}
        </div>
      )}
      {(isDeathmatch || isEscalation || isTeamDeathmatch) && (
        <div className="col-kpm">
          <p className={isTitleRow ? "type-caption" : "type-body2"}>
            {killsPerMin}
          </p>
        </div>
      )}
    </PlayerRow>
  );
};

const TeamPanel = ({
  title,
  team,
  isDeathmatch,
  isEscalation,
  isTeamDeathmatch,
  isEnemy,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Row
        title={title}
        winRate={t("common:winPercent", "Win %")}
        kda={t("val:KDA", "KDA")}
        hsPercent={t("common:stats.hsPercent", "HS %")}
        adr={t("common:stats.adr", "ADR")}
        avgScore={t("val:avgScore", "Avg. Score")}
        history={t("val:history", "History")}
        kdaText={t("val:KDA", "KDA")}
        killsPerMin={t("val:killsPerMin", "Kills/Min.")}
        isTitleRow
        isDeathmatch={isDeathmatch}
        isEscalation={isEscalation}
        isTeamDeathmatch={isTeamDeathmatch}
        isEnemy={isEnemy}
      />
      {team.map((p, index) => {
        const teamColor =
          isEnemy || isDeathmatch ? "var(--red)" : "var(--turq)";
        const borderColor = p.isUser ? "var(--yellow);" : teamColor;
        return (
          <Row
            key={p.playerId}
            competitiveTier={p.competitiveTier}
            agentImg={p.agentImg}
            agentName={p.agentName}
            agentKey={p.agentKey}
            playerName={p.playerName}
            tagLine={p.tagLine}
            kda={p.kda}
            winRate={p.winRate}
            hsPercent={p.hsPercent}
            borderColor={borderColor}
            isUser={p.isUser}
            isDeathmatch={isDeathmatch}
            isEscalation={isEscalation}
            isTeamDeathmatch={isTeamDeathmatch}
            position={index + 1}
            href={p.href}
            adr={p.adr}
            avgScore={p.avgScore}
            history={p.history}
            kdaText={p.kdaText}
            killsPerMin={p.killsPerMin}
            incognito={p.incognito}
          />
        );
      })}
    </div>
  );
};

const TeamBreakdown = ({
  currentPlayer,
  players,
  perPlayerStats,
  actId,
  cmsAgents,
  isDeathmatch,
  isEscalation,
  isCustom,
  isTeamDeathmatch,
  queue,
}) => {
  const { t } = useTranslation();
  const {
    val: { profiles, playerMatchWinHistory },
  } = useSnapshot(readState);

  const profileId = getNameTag(currentPlayer.gameName, currentPlayer.tagLine);

  const prepareStats = useCallback(
    (player) => {
      const playerId = getNameTag(player.gameName, player.tagLine);
      const playerProfile = profiles[playerId];
      if (!playerProfile || playerProfile instanceof Error) return null;
      const playerStats = perPlayerStats[playerId];
      const winHistory =
        playerMatchWinHistory[playerProfile?.puuid]?.[actId]?.[queue];
      const matchWinHistory = !(winHistory instanceof Error) ? winHistory : [];
      const isUser = playerId === profileId;
      const cmsAgent = cmsAgents.find(
        (a) => a.uuid.toLowerCase() === player.agent.uuid.toLowerCase(),
      );
      const agentImg = cmsAgent?.images.matchtile.url;

      const kda = playerStats
        ? calcRate(
            (playerStats.kills || 0) + (playerStats.assists || 0),
            playerStats.deaths || 0,
            2,
          )
        : "-";
      const formattedHSPercent = playerStats
        ? displayRate(
            playerStats.headshots,
            playerStats.headshots +
              playerStats.bodyshots +
              playerStats.legshots,
            0,
            1,
          )
        : "-";

      const winRate = playerStats
        ? calcWinrate({
            wins: playerStats.matchesWon,
            matches: playerStats.matchesPlayed,
          })
        : "-";
      const winRateString = playerStats ? displayRate(winRate, 100) : "-";

      const adr = playerStats
        ? calcRate(playerStats.damagePerRound, playerStats.matchesPlayed)
        : "-";
      const adrString = playerStats
        ? adr.toLocaleString(getLocale(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : "-";

      const avgScore = playerStats
        ? calcRate(playerStats.score, playerStats.roundsPlayed)
        : "-";
      const avgScoreString = playerStats
        ? avgScore.toLocaleString(getLocale(), {
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })
        : "-";

      const kdaText = t("val:kdaWritteOut", "{{k}} / {{d}} / {{a}}", {
        k: playerStats
          ? calcRate(playerStats.kills, playerStats.matchesPlayed, 0)
          : "-",
        d: playerStats
          ? calcRate(playerStats.deaths, playerStats.matchesPlayed, 0)
          : "-",
        a: playerStats
          ? calcRate(playerStats.assists, playerStats.matchesPlayed, 0)
          : "-",
      });

      const killsPerMin = playerStats
        ? calcRate(playerStats.kills, playerStats.playDuration, 0)
        : "-";

      return {
        playerId,
        teamId: player.teamId,
        playerName: player.gameName,
        tagLine: player.tagLine,
        agentName: cmsAgent?.name,
        agentKey: cmsAgent?.key,
        competitiveTier: playerProfile.valorantProfile.latestTier,
        isUser,
        agentImg,
        score: playerStats?.score || 0,
        kills: playerStats?.kills || 0,
        kdaText,
        kda,
        killsPerMin,
        hsPercent: formattedHSPercent,
        winRate: winRateString,
        href: `/valorant/profile/${playerId}`,
        adr: adrString,
        avgScore: avgScoreString,
        history: matchWinHistory,
        incognito: player.incognito,
      };
    },
    [
      actId,
      cmsAgents,
      perPlayerStats,
      playerMatchWinHistory,
      profileId,
      profiles,
      queue,
      t,
    ],
  );

  const playerRows = useMemo(() => {
    return players.map((p) => prepareStats(p)).filter((p) => p);
  }, [players, prepareStats]);

  if (isDeathmatch) {
    const sortedPlayers = orderBy(
      orderBy([...playerRows], (player) => player.score, "desc"),
      (player) => player.kills,
      "desc",
    );
    return (
      <CardContainer>
        <TeamPanel
          title={t("common:player", "Player")}
          team={sortedPlayers}
          isDeathmatch
        />
      </CardContainer>
    );
  }

  const enemyTeam = playerRows.filter(
    (p) => p?.teamId !== currentPlayer.teamId,
  );
  const myTeam = playerRows.filter((p) => p?.teamId === currentPlayer.teamId);

  return (
    <CardContainer>
      <TeamPanel
        title={t("common:myteam", "My Team")}
        team={myTeam}
        isEscalation={isEscalation}
        isCustom={isCustom}
        isTeamDeathmatch={isTeamDeathmatch}
      />
      <TeamPanel
        title={t("common:enemyteam", "Enemy Team")}
        team={enemyTeam}
        isEscalation={isEscalation}
        isCustom={isCustom}
        isTeamDeathmatch={isTeamDeathmatch}
        isEnemy
      />
    </CardContainer>
  );
};

export default TeamBreakdown;
